import React from 'react';

import {
    container,
    title,
    accent,
    icon,
    subtitle as subtitleClass,
} from './contest-thank-you.module.scss';

import IconSent from '../../assets/images/svg/icon_completed.svg';

export interface IContestThankYouProps {
    className?: string;
    titleFirstLine?: string;
    titleSecondLine?: string;
    subtitle?: string;
}

const ContestThankYou: React.FC<IContestThankYouProps> = ({
    className = '',
    titleSecondLine,
    titleFirstLine,
    subtitle,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <IconSent className={icon} />
            {(titleFirstLine || titleSecondLine) && (
                <h1 className={title}>
                    {titleFirstLine && <span>{titleFirstLine}</span>}
                    {titleSecondLine && <span className={accent}>{titleSecondLine}</span>}
                </h1>
            )}
            {subtitle && <p className={subtitleClass}>{subtitle}</p>}
        </div>
    );
};

export default ContestThankYou;
