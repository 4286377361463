import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, layout } from './zabka-contest-closed-page.module.scss';
import { IPage } from '../models/page.model';

import ZabkaContestLayout from '../layouts/zabka-contest-layout';

interface IZabkaContestClosedPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const ZabkaContestClosedPage: React.FC<IZabkaContestClosedPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;

    return (
        <ZabkaContestLayout
            page={page}
            className={container}
            layoutClass={layout}
            headerProps={{
                titleFirstLine: t('zabka.contest.closed.title.1'),
                titleSecondLine: t('zabka.contest.closed.title.2'),
            }}
        ></ZabkaContestLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ZabkaContestClosedPage;
